// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container } from '@mui/material';
// components
import { useQuery } from '@tanstack/react-query';
import { getAgencyDashboard } from '../../api/agency.api';
import Page from '../../components/Page';
import AppWebsiteVisits from './sections/YearlyBookingSummary';
import AppCurrentVisits from './sections/BookingSourceSummary';
import IncomeSummary from './sections/IncomeSummary';
import BookingSummary from './sections/BookingSummary';


export default function DashboardApp() {
  const theme = useTheme();

  const { data: dashboardData } = useQuery(['getDashboard'], getAgencyDashboard);

  const chartValues = dashboardData?.data?.yearSummary?.map((sumValue) => sumValue.bookings)
  const chartLabels = dashboardData?.data?.yearSummary?.map((sumValue) => sumValue.month)

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Grid container spacing={3}>

        <Grid item xs={12} sm={6} md={3}>
            <IncomeSummary
              title="Today Revenue"
              total={dashboardData?.data?.revenueSummary?.today}
              color="primary"
              icon={'mdi:money'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <IncomeSummary
              title="Weekly Revenue"
              total={dashboardData?.data?.revenueSummary?.weekly}
              color="info"
              icon={'mdi:money'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <IncomeSummary
              title="Monthly Revenue"
              total={dashboardData?.data?.revenueSummary?.monthly}
              color="warning"
              icon={'mdi:money'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <IncomeSummary
              title="Last Month Revenue"
              total={dashboardData?.data?.revenueSummary?.lastMonth}
              color="error"
              icon={'mdi:money'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <BookingSummary
              title="Weekly Bookings"
              total={dashboardData?.data?.stats?.weeklyBookings}
              color="primary"
              icon={'ant-design:fund-filled'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <BookingSummary
              title="Monthly Bookings"
              total={dashboardData?.data?.stats?.monthlyBookings}
              color="info"
              icon={'ant-design:fund-filled'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <BookingSummary
              title="Bookings last month"
              total={dashboardData?.data?.stats?.lastMonthBookings}
              color="warning"
              icon={'ant-design:fund-filled'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <BookingSummary
              title="Trip packages"
              total={dashboardData?.data?.stats?.totalTrips}
              color="error"
              icon={'ant-design:tags-filled'}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Yearly booking summary (last 12 months)"
              subheader="Number of bookings for each month"
              chartLabels={chartLabels}
              chartData={[
                {
                  name: 'Bookings',
                  type: 'column',
                  fill: 'solid',
                  data: chartValues,
                },
                // {
                //   name: 'Team B',
                //   type: 'area',
                //   fill: 'gradient',
                //   data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                // },
                // {
                //   name: 'Team C',
                //   type: 'line',
                //   fill: 'solid',
                //   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                // },
              ]}
            />

          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Booking Sources"
              chartData={[
                { label: 'Portal', value: dashboardData?.data?.sources?.portalCount || 0 },
                { label: 'Travena App', value: dashboardData?.data?.sources?.travenaAppCount || 0 },
                { label: 'Travena Web', value: dashboardData?.data?.sources?.travenaWebCount || 0 },
              ]}
              chartColors={[theme.palette.primary.main, theme.palette.chart.blue[0], "#7b68ee"]}
            />
          </Grid>


        </Grid>
      </Container>
    </Page>
  );
}

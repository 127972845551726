import React, { useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  OutlinedInput,
  InputAdornment,
  Autocomplete,
  TextField,
  Box,
  // OutlinedInput,
  // InputAdornment,
} from '@mui/material';
// components
import { useQuery } from '@tanstack/react-query';
import { formatDate } from '../../utils/formatTime';
import { getAgencyBookings } from '../../api/bookings.api';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import SliderModal from '../../components/slider-modal/SliderModal';
import pallete from '../../theme/palette';
import NewBooking from './newBooking/NewBooking';
import BookingDetails from './bookingDetails/BookingDetails';
import { getAgencyTrips } from '../../api/trips.api';
import DateRangePicker from '../../components/DateRangePicker';

const TABLE_HEADS = [
  { label: 'CUSTOMER' },
  { label: 'TRIP' },
  { label: 'SELECTED PACKAGE' },
  { label: 'SELECTED DATE' },
  { label: 'BOOKING DATE' },
  { label: 'PAYMENT STATUS' },
  { label: 'ACTIONS' },
];

export default function AgencyBookings() {
  const [page, setPage] = useState(0);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [nothingFound, setNothingFound] = useState(false);
  const [bookingsList, setBookingsList] = useState([]);
  const [newBookingFormOpen, setNewBookingOpen] = useState(false);
  const [bookingDetailsOpen, setBookingDetailsOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState({});
  const [selectedTrip, setSelectedTrip] = useState({});
  const [, setSelectedDates] = useState();

  const { data: agencyTrips } = useQuery(['getAgencyTrips'], getAgencyTrips, {
    initialData: [],
  });

  const { refetch } = useQuery(['getAgencyBookings'], getAgencyBookings, {
    onSuccess: (data) => {
      setBookingsList(data.data || []);
      setFilteredBookings(data.data || []);
      setNothingFound(data.data.length === 0);
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - bookingsList.length) : 0;

  return (
    <>
      <Page title="Bookings">
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
            <Typography variant="h4" gutterBottom>
              Bookings
            </Typography>
            <Button
              variant="contained"
              onClick={() => setNewBookingOpen(true)}
              startIcon={<Iconify icon="eva:plus-outline" />}
            >
              New Booking
            </Button>
          </Stack>

          <Card sx={{ marginBottom: 2 }}>
            <Typography marginTop={1} paddingX={2}>Search or Filter</Typography>
            <Stack direction="row" alignItems="center" spacing={3} padding={2}>
              <OutlinedInput
                value={filterName}
                onChange={(e) => {
                  setFilterName(e.target.value);
                }}
                fullWidth
                placeholder="Search..."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-outline" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
                size="small"
              />
              <Autocomplete
                renderInput={(params) => <TextField size="small" {...params} label="Trip" />}
                options={agencyTrips?.data || []}
                getOptionLabel={(option) => option.tripTitle || ''}
                value={selectedTrip || ''}
                fullWidth
                onChange={(_, newValue) => setSelectedTrip(newValue)}
                size="small"
              />

              <DateRangePicker
                onSelect={(dateRanges) => {
                  setSelectedDates(dateRanges);
                  console.log(dateRanges);
                }}
                size="small"
              />

              <Box>
                <Button variant="outlined" sx={{ width: 150 }} endIcon={<Iconify icon="ion:filter" />}>
                  Apply Filters
                </Button>
              </Box>
            </Stack>
          </Card>

          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead
                    sx={{
                      backgroundColor: '#00ab66cc',
                    }}
                  >
                    <TableRow>
                      {TABLE_HEADS.map((header) => (
                        <TableCell key={header.label}>{header.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredBookings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const {
                        id,
                        // numberOfPeople,
                        // numberOfKids,
                        paymentStatus,
                        tripCostPackage,
                        tripCalendar,
                        createdAt,
                        // totalCost,
                        // amountPaid,
                        bookingContact,
                        bookingSource,
                        tripTitle,
                      } = row;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox">
                          <TableCell component="th" scope="row">
                            <Stack direction="column" spacing={2}>
                              <Typography variant="subtitle2" noWrap sx={{ lineHeight: 0.8 }}>
                                {bookingContact?.fullName}
                              </Typography>
                              <Typography variant="caption" noWrap sx={{ lineHeight: 0.9 }}>
                                {bookingContact?.email}
                              </Typography>
                              <Typography variant="caption" noWrap sx={{ lineHeight: 0.8 }}>
                                {bookingContact?.phoneNumber}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{tripTitle}</TableCell>
                          <TableCell align="left">
                            <Typography variant="subtitle2">{tripCostPackage.packageName}</Typography>
                            <Typography variant="subtitle2">{tripCostPackage.packageCost}</Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="subtitle2">
                              {formatDate(tripCalendar.startDate, 'dd MMM, yy')} -{' '}
                              {formatDate(tripCalendar.endDate, 'dd MMM, yy')}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Stack spacing={1}>
                              <Typography>{formatDate(createdAt, 'dd MMM, yy')}</Typography>
                              <Label variant="ghost" color={(bookingSource !== 'AGENCY PORTAL' && 'info') || 'warning'}>
                                {bookingSource}
                              </Label>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color={(paymentStatus !== 'PAID' && 'error') || 'success'}>
                              {paymentStatus}
                            </Label>
                          </TableCell>

                          <TableCell align="left">
                            <Iconify
                              onClick={() => {
                                setSelectedBooking(row);
                                setBookingDetailsOpen(true);
                              }}
                              icon="eva:info-outline"
                              width={30}
                              height={30}
                              sx={{
                                color: pallete.info.main,
                                marginRight: 2,
                              }}
                            />

                            {/* <Iconify
                              onClick={() => {
                                setSelectedBooking(row);
                                setBookingDetailsOpen(true);
                              }}
                              icon="eva:trash-outline"
                              width={30}
                              height={30}
                              sx={{
                                color: pallete.error.main,
                                marginRight: 2,
                              }}
                            /> */}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {nothingFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={bookingsList.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
      <SliderModal openState={newBookingFormOpen} setOpenState={setNewBookingOpen}>
        <NewBooking refetch={refetch} setOpenState={setNewBookingOpen} />
      </SliderModal>

      <SliderModal openState={bookingDetailsOpen} setOpenState={setBookingDetailsOpen}>
        <BookingDetails refetch={refetch} setOpenState={setBookingDetailsOpen} booking={selectedBooking} />
      </SliderModal>
    </>
  );
}

/* eslint-disable react/prop-types */
import { Button, Card, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { getAllPermissions } from '../../../../api/user.api';
import Iconify from '../../../../components/Iconify';

const RoleForm = (props) => {
  const { state, dispatch } = props;

  const [allPermissions, setAllPermissions] = useState([]);

  const { isLoading } = useQuery(['getPermissions'], () => getAllPermissions(), {
    onSuccess: (data) => {
      const perms = Object.values(data).slice(3);
      setAllPermissions(perms);
      if(state.permissions?.includes('ALL')){
        dispatch({ name: 'permissions', value: perms });
      }
    },
  });

  const handlePermissionClicked = (permission) => {
    const permissions = state.permissions || [];

    if (permission === 'ALL') {
      const allPermCopy = [...allPermissions];
      dispatch({ name: 'permissions', value: allPermCopy });
      return;
    }

    if (permissions.includes(permission)) {
      permissions.splice(permissions.indexOf(permission), 1);
    } else {
      permissions.push(permission);
    }

    dispatch({ name: 'permissions', value: permissions });
  };

  return (
    <Card sx={{ padding: 3 }}>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Role Details
      </Typography>
      <Stack direction="column" spacing={3}>
        <TextField
          type="text"
          label="Role Name *"
          value={state.name}
          onChange={(e) => {
            dispatch({ name: 'name', value: e.target.value });
          }}
          fullWidth
        />
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Permissions
        </Typography>

        {isLoading && <CircularProgress color="primary" />}

        <Grid container spacing={2}>
          <Grid item md={6}>
            <Button
              color={state.permissions?.length === allPermissions.length ? 'primary' : 'inherit'}
              endIcon={
                state.permissions?.length === allPermissions.length ? (
                  <Iconify icon="eva:checkmark-circle-2-fill" color="primary" />
                ) : (
                  ''
                )
              }
              onClick={() => handlePermissionClicked('ALL')}
              variant="outlined"
              sx={{ height: '100%', width: '100%' }}
            >
              GRANT ALL PERMISSIONS
            </Button>
          </Grid>
          <Grid item md={6}>
            <Button
              color="warning"
              onClick={() => dispatch({name: 'permissions', value: []})}
              variant="outlined"
              sx={{ height: '100%', width: '100%' }}
            >
              REMOVE ALL PERMISSIONS
            </Button>
          </Grid>
          {allPermissions.map((permission) => (
            <Grid key={permission} item md={3}>
              <Button
                title={permission}
                color={state.permissions?.includes(permission) ? 'primary' : 'inherit'}
                endIcon={
                  state.permissions?.includes(permission) ? (
                    <Iconify icon="eva:checkmark-circle-2-fill" color="primary" />
                  ) : (
                    ''
                  )
                }
                onClick={() => handlePermissionClicked(permission)}
                variant="outlined"
                sx={{ height: '100%', width: '100%' }}
              >
                {permission}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Card>
  );
};

export default RoleForm;

import { getCookie } from '../utils/cookies';
import axiosClient from '../axios/AxiosClient';
import { bookingsEndpoints } from './bookings.endpoints';

export const getAgencyBookings = async () => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.get(bookingsEndpoints.getBookings(agencyId));
  return results?.data;
};

export const getAgencyBookingReports = async (startDate, endDate) => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.get(bookingsEndpoints.bookingReports(agencyId, startDate, endDate));
  return results?.data;
};

export const createBooking = async (booking) => {
  const results = await axiosClient.post(bookingsEndpoints.createBooking, booking);
  return results?.data;
};

export const updateBooking = async (booking) => {
  const results = await axiosClient.put(bookingsEndpoints.editBooking, booking);
  return results?.data;
};

/* eslint-disable react/prop-types */
import { Button, Card, Stack, TextField, Typography } from '@mui/material';

export default function TripActivityDetails({ setOpenState, activityDetail }) {

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div>
          <div className="slider-header-title">Activity Details</div>
          <div>{activityDetail.assosiatedTrip?.tripTitle}</div>
        </div>
        <div className="slider-header-buttons">
          <Button variant="outlined" sx={{ marginRight: 2 }} onClick={() => setOpenState(false)}>
            Cancel
          </Button>
        </div>
      </div>
      <div className="slider-display-body">
        <Stack spacing={2}>
          <Card sx={{ padding: 3 }}>
            <Typography variant="h6" sx={{ marginBottom: 3 }}>
              Dates
            </Typography>

          </Card>

          <Card sx={{ padding: 3 }}>
            <Typography variant="h6" sx={{ marginBottom: 3 }}>
              Assigned Personnels
            </Typography>

          </Card>

          <Card sx={{ padding: 3 }}>
            <Typography variant="h6" sx={{ marginBottom: 3 }}>
              Assigned Equipments
            </Typography>

          </Card>

          <Card sx={{ padding: 3 }}>
            <Typography variant="h6" sx={{ marginBottom: 3 }}>
              Notes
            </Typography>
            <Stack direction="column" spacing={3}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                <TextField
                  type="text"
                  label="Notes"
                  value={activityDetail.notes || ''}
                  fullWidth
                />
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </div>
    </div>
  );
}

/* eslint-disable react/prop-types */
import { Button } from '@mui/material';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import UserForm from '../userForm/UserForm';
import { createAgencyUser } from '../../../../api/agency.api';
import { Toast } from '../../../../axios/Toast';

const UserDetails = ({ setOpenState, userDetails, refetchUsers }) => {
  const [state, setState] = useState(userDetails);

  const putUserMutation = useMutation(['updateUser'], () => createAgencyUser(state), {
    onMutate: () => {
      Toast.loading('Loading, please wait...');
    },
    onError: () => {
      Toast.error('An error occurred, check filled data and try again');
    },
    onSuccess: (data) => {
      if (data.success) {
        Toast.success('User Updated successfully');
        refetchUsers();
        setOpenState(false);
      } else {
        Toast.error(data.message || 'An error occurred, check filled data and try again');
      }
    },
  });

  const onChange = ({ name, value }) => {
    setState({ ...state, [name]: value });
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">User Details</div>
        <div className="slider-header-title">{userDetails.fullName}</div>
        <div className="slider-header-buttons">
          <Button variant="outlined" sx={{ marginRight: 2 }} onClick={() => setOpenState(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => putUserMutation()}>
            Save
          </Button>
        </div>
      </div>
      <div className="slider-display-body">
        <UserForm state={state} dispatch={onChange} />
      </div>
    </div>
  );
};

export default UserDetails;

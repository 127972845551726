import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// components
import { AppContext } from '../../context/AppContext';
import Iconify from '../../components/Iconify';
//
import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 250;
const APPBAR_MOBILE = 55;
const APPBAR_DESKTOP = 60;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  return (
    <AppContext.Consumer>
      {(value) => (
        <RootStyle style={{ zIndex: value.sliderModalOpen ? -1 : 1 }}>
          <ToolbarStyle>
            <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
              <Iconify icon="eva:menu-2-fill" />
            </IconButton>
            {/* 
          <Searchbar /> */}
            <Box sx={{ flexGrow: 1 }} />

            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
              {/* <LanguagePopover />
            <NotificationsPopover /> */}
              <AccountPopover />
            </Stack>
          </ToolbarStyle>
        </RootStyle>
      )}
    </AppContext.Consumer>
  );
}

/* eslint-disable react/prop-types */
import { Autocomplete, Card, Grid, Stack, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { getAgencyRoles } from '../../../../api/agency.api';

const UserForm = (props) => {
  const { state, dispatch } = props;

  const [rolesList, setRolesList] = useState([]);

  useQuery(['getAgencyRoles'], () => getAgencyRoles(), {
    onSuccess: (data) => {
      setRolesList(data?.data || []);
    },
  });

  return (
    <Stack>
      <Card sx={{ padding: 3 }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          User Information
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              type="text"
              label="Full Name *"
              value={state.fullName || state.userProfile?.fullName || ''}
              onChange={(e) => {
                dispatch({ name: 'fullName', value: e.target.value });
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              type="email"
              label="Email *"
              value={state.email || state.userProfile?.email || ''}
              onChange={(e) => {
                dispatch({ name: 'email', value: e.target.value });
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              type="text"
              label="Phone number *"
              value={state.phoneNumber || state.userProfile?.phoneNumber || ''}
              onChange={(e) => {
                dispatch({ name: 'phoneNumber', value: e.target.value });
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              renderInput={(params) => <TextField {...params} label="Role *" />}
              options={rolesList || []}
              getOptionLabel={(option) => option.name || ''}
              value={state.agencyRole || ''}
              fullWidth
              onChange={(e, newValue) => {
                dispatch({ name: 'agencyRole', value: newValue });
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </Stack>
  );
};

export default UserForm;

/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-nested-ternary */
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import './non-sticky-slider.css';
import './slider-display.css';
import { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { AppContext } from '../../context/AppContext';

const SliderModal = ({ children, openState, setOpenState, width, from, height }) => {
  const WIDTH = width ? `${width}vw` : '60vw';
  const isMobile = useMediaQuery('(max-width:768px)');

  const { setSliderModalOpen } = useContext(AppContext);

  const sliderHeight =
    height === 30
      ? 'slider-quarter'
      : null || height === 50
      ? 'slider-half'
      : null || height === 75
      ? 'slider-three-quarter'
      : null;

  return (
    <div>
      <SlidingPane
        className={`non-sticky-slider ${sliderHeight}`}
        isOpen={openState}
        hideHeader={true}
        width={isMobile ? '100vw' : WIDTH}
        from={from}
        height={height}
        onRequestClose={() => {
          setOpenState(false);
        }}
        onAfterClose={() => {
          if (from !== 'bottom') {
            setSliderModalOpen(false);
          }
        }}
        onAfterOpen={() => {
          if (from !== 'bottom') {
            setSliderModalOpen(true);
          }
        }}
      >
        {children}
      </SlidingPane>
    </div>
  );
};

export default SliderModal;

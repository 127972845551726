import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Container, Tab } from '@mui/material';
import { useState } from 'react';
import Page from '../../components/Page';
import AgencyPersonel from './AgencyPersonel';
import AgencyEquipment from '../equipments/AgencyEquipments';

export default function AgencyOperatorsTabs() {
  const [activeTab, setActiveTab] = useState('Personel');

  return (
    <Page title="Operations">
      <Container maxWidth="xl">
        <TabContext value={activeTab}>
          <TabList onChange={(_, value) => setActiveTab(value)}>
            <Tab label="Personel" value="Personel" />
            <Tab label="Equipments" value="Equipments" />
          </TabList>

          <TabPanel value='Personel'>
            <AgencyPersonel />
          </TabPanel>

          <TabPanel value='Equipments'>
            <AgencyEquipment />
          </TabPanel>


        </TabContext>
      </Container>
    </Page>
  );
}

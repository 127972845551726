import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import SettlementSummary from './SettlementsSummary';
import { getAgencySettlementAcct, getAgencySettlements } from '../../api/agency.api';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import pallete from '../../theme/palette';
import { formatDate } from '../../utils/formatTime';
import { fCurrency } from '../../utils/formatNumber';
import Label from '../../components/Label';
import SliderModal from '../../components/slider-modal/SliderModal';
import NewSettlementAcct from './createSettlementAcct/NewSettlementAcct';

const TABLE_HEADS = [
  { label: 'SETTLEMENT DATE' },
  { label: 'AMOUNT' },
  { label: 'TRANSACTION REF' },
  { label: 'BANK' },
  { label: 'ACCOUNT NUMBER' },
  { label: 'MONTH' },
  { label: 'STATUS' },
  { label: 'ACTIONS' },
];

const SettlementsPage = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filteredSettlements, setFilteredSettlements] = useState([]);
  const [nothingFound, setNothingFound] = useState(false);
  const [settlementsList, setSettlementsList] = useState([]);

  const [openSettlementAcct, setOpenSettlementAcct] = useState(false);

  const { data: settlementAcct, refetch: refetchAcct } = useQuery(['getSettlementAccount'], getAgencySettlementAcct);

  useQuery(['getSettlements'], getAgencySettlements, {
    onSuccess: (data) => {
      setNothingFound(!data.data);
      setFilteredSettlements(data.data || []);
      setSettlementsList(data.data || []);
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - settlementsList.length) : 0;

  return (
    <>
      <Page title="Accounting">
        <Container maxWidth="xl">
          <Box paddingX={2} paddingY={1} borderLeft="2px solid black" bgcolor="white" marginY={2}>
            <Grid container spacing={2}>
              {settlementAcct?.data ? (
                <>
                  {' '}
                  <Grid item xs={12} md={3}>
                    <Typography fontWeight="bold">Settlement Account: </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Typography variant="h5">{settlementAcct.data.bankName}</Typography>
                      <Iconify icon="ic:baseline-circle" />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="h6" color="primary">
                      {settlementAcct.data.accountNumber} - {settlementAcct.data.accountName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Button size="small" variant="contained" color="secondary">
                      Change
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} md={10}>
                    <Typography>You have not set up your settlement account!</Typography>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Button size="small" variant="contained" onClick={() => setOpenSettlementAcct(true)}>
                      Setup Now
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <SettlementSummary
                title="Next Payout"
                total="2000"
                color="primary"
                icon={'ic:outline-payments'}
                referenceDate="23 May, 24"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <SettlementSummary
                title="Last Payout"
                total="45000"
                color="info"
                icon={'ic:outline-payments'}
                referenceDate="23 May, 24"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <SettlementSummary
                title="Monthly Total"
                total="100000"
                color="warning"
                icon={'ic:outline-payments'}
                referenceDate="23 May, 24"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <SettlementSummary
                title="Last Month"
                total="130000"
                color="success"
                icon={'ic:outline-payments'}
                referenceDate="23 May, 24"
              />
            </Grid>
          </Grid>

          <Typography marginY={2} variant="h5">
            Settlements
          </Typography>

          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead
                    sx={{
                      backgroundColor: '#00ab66cc',
                    }}
                  >
                    <TableRow>
                      {TABLE_HEADS.map((header) => (
                        <TableCell key={header.label}>{header.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredSettlements
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          totalAmount,
                          settlementStatus,
                          settlementMonthYear,
                          transactionId,
                          settlementBank,
                          settlementAccount,
                          settlementDate,
                        } = row;

                        return (
                          <TableRow
                            hover
                            key={row.id}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell>{formatDate(settlementDate)}</TableCell>
                            <TableCell>KSh {fCurrency(totalAmount)}.00</TableCell>
                            <TableCell>{transactionId || 'N/A'}</TableCell>
                            <TableCell>{settlementBank || 'N/A'}</TableCell>
                            <TableCell>{settlementAccount || 'N/A'}</TableCell>
                            <TableCell>{settlementMonthYear}</TableCell>
                            <TableCell>
                              {' '}
                              <Label variant="ghost" color={(settlementStatus !== 'Pending' && 'error') || 'success'}>
                                {settlementStatus}
                              </Label>
                            </TableCell>
                            <TableCell>
                              <Iconify
                                icon="eva:info-outline"
                                width={30}
                                height={30}
                                sx={{
                                  color: pallete,
                                  marginRight: 2,
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {nothingFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery="No data" />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={settlementsList.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>

        <SliderModal openState={openSettlementAcct} setOpenState={setOpenSettlementAcct}>
          <NewSettlementAcct refetch={refetchAcct} setOpenState={setOpenSettlementAcct} />
        </SliderModal>
      </Page>
    </>
  );
};

export default SettlementsPage;

/* eslint-disable react/prop-types */
import { Autocomplete, Card, Grid, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getBankNames } from '../../../api/agency.api';

// eslint-disable-next-line react/prop-types
const SettlementAcctForm = ({ state, dispatch }) => {
  const { data: bankNames } = useQuery(['getBankNames'], getBankNames, {
    initialData: {},
  });

  return (
    <div>
      <Card sx={{ padding: 3 }}>
        <Typography variant="h6" sx={{ marginBottom: 3 }}>
          Settlement Account
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              renderInput={(params) => <TextField {...params} label="Bank *" />}
              options={bankNames?.data || []}
              getOptionLabel={(option) => option || ''}
              value={state?.bankName || ''}
              fullWidth
              onChange={(_, newValue) => dispatch({ name: 'bankName', value: newValue })}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              type="text"
              label="Account Number"
              value={state?.accountNumber || ''}
              onChange={(e) => {
                dispatch({ name: 'accountNumber', value: e.target.value });
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              type="text"
              label="Account Name"
              value={state?.accountName || ''}
              onChange={(e) => {
                dispatch({ name: 'accountName', value: e.target.value });
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default SettlementAcctForm;

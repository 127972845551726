/* eslint-disable react/prop-types */
import { Button } from '@mui/material';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { createAgencyRole } from '../../../../api/agency.api';
import { getCookie } from '../../../../utils/cookies';
import RoleForm from '../roleForm/RoleForm';
import { Toast } from '../../../../axios/Toast';

const NewRole = ({ setOpenState, refetchRoles }) => {
  const { agencyId } = getCookie('UserInfo');
  const [state, setState] = useState({ agencyId });

  const onChange = ({ name, value }) => {
    setState({ ...state, [name]: value });
  };

  const postRoleMutation = useMutation(['createRole'], () => createAgencyRole(state), {
    onMutate: () => {
      Toast.loading('Loading, please wait...');
    },
    onError: () => {
      Toast.error('An error occurred, check filled data and try again');
    },
    onSuccess: (data) => {
      if (data.success) {
        Toast.success('Role created successfully');
        refetchRoles();
        setOpenState(false);
      } else {
        Toast.error(data.message || 'An error occurred, check filled data and try again');
      }
    },
  });

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">Create Role</div>
        <div className="slider-header-buttons">
          <Button
            variant="outlined"
            sx={{
              marginRight: 5,
            }}
            onClick={() => setOpenState(false)}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={() => postRoleMutation.mutate()}>
            Save
          </Button>
        </div>
      </div>
      <div className="slider-display-body">
        <RoleForm state={state} dispatch={onChange} />
      </div>
    </div>
  );
};

export default NewRole;

const bookingFormReducer = (state, action) => {
    if(action.category){
        let actionObject = state[action.category] || {}
        actionObject = { ...actionObject, [action.name]:action.value }
      return { ...state, [action.category]: actionObject}
    }

    if(action.name){
        return { ...state, [action.name]:action.value}
    }

    console.log(action);
    
    return state
    
}

export default bookingFormReducer
import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Toast } from '../../../axios/Toast';
import { saveSettlementAcct } from '../../../api/agency.api';
import SettlementAcctForm from '../settlementForm/SettlementForm';

// eslint-disable-next-line react/prop-types
const NewSettlementAcct = ({ setOpenState, refetch }) => {
  const [state, setState] = useState();

  const saveSettlementAccount = useMutation(['saveSettlementAccount'], () => saveSettlementAcct(state), {
    onSettled: () => {
      refetch();
      setOpenState(false);
    },
    onSuccess: (response) => {
      if (response.success) {
        Toast.success(response.message);
      } else {
        Toast.error(response.message);
      }
    },
    onError: () => {
      Toast.error('Error occurred');
    },
  });

  const onSave = () => {
    Toast.loading('Please wait..');
    saveSettlementAccount.mutate();
  };

  const handleChange = ({ name, value }) => {
    setState({ ...state, [name]: value });
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">Setup Settlement Account</div>
        <div className="slider-header-buttons">
          <Button variant="outlined" sx={{ marginRight: 2 }} onClick={() => setOpenState(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => onSave()}>
            Save
          </Button>
        </div>
      </div>
      <div className="slider-display-body">
        <SettlementAcctForm state={state} dispatch={handleChange} />
      </div>
    </div>
  );
};

export default NewSettlementAcct;

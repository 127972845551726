/* eslint-disable react/prop-types */
import { Button } from '@mui/material';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { createAgencyUser } from '../../../../api/agency.api';
import { Toast } from '../../../../axios/Toast';
import UserForm from '../userForm/UserForm';

const NewUser = ({ setOpenState, refetchUsers }) => {
  const [state, setState] = useState({});

  const postUserMutation = useMutation(['createUser'], () => createAgencyUser(state), {
    onMutate: () => {
      Toast.loading('Loading, please wait...');
    },
    onError: () => {
      Toast.error('An error occurred, check filled data and try again');
    },
    onSuccess: (data) => {
      console.log(data);
      if (data.success) {
        Toast.success('User created successfully');
        refetchUsers();
        setOpenState(false);
      } else {
        Toast.error(data.message || 'An error occurred, check filled data and try again');
      }
    },
  });

  const onChange = ({ name, value }) => {
    setState({ ...state, [name]: value });
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">Create User</div>
        <div className="slider-header-buttons">
          <Button
            variant="outlined"
            sx={{
              marginRight: 5,
            }}
            onClick={() => setOpenState(false)}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={() => postUserMutation.mutate()}>
            Save
          </Button>
        </div>
      </div>
      <div className="slider-display-body">
        <UserForm state={state} dispatch={onChange} />
      </div>
    </div>
  );
};

export default NewUser;

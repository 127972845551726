/* eslint-disable react/prop-types */
import { Autocomplete, Card, Grid, Stack, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getPersonelType } from '../../../api/agency.api';

// eslint-disable-next-line react/prop-types
const PersonelForm = ({ state, dispatch }) => {
  const { data: personelTypes } = useQuery(['getAgencyPersonelType'], getPersonelType, {
    initialData: {},
  });

  return (
    <div>
      <>
        <Card sx={{ padding: 3 }}>
          <Typography variant="h6" sx={{ marginBottom: 3 }}>
            Person Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                label="Full name *"
                value={state?.fullName || ''}
                onChange={(e) => {
                  dispatch({ name: 'fullName', value: e.target.value });
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                label="Phone number *"
                value={state?.phoneNumber || ''}
                onChange={(e) => {
                  dispatch({ name: 'phoneNumber', value: e.target.value });
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Autocomplete
                renderInput={(params) => <TextField {...params} label="Designation *" />}
                options={personelTypes?.data || []}
                getOptionLabel={(option) => option.typeName || ''}
                value={state?.operatorType || ''}
                fullWidth
                onChange={(_, newValue) => dispatch({ name: 'operatorType', value: newValue })}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                type="number"
                label="ID Number"
                value={state?.idNumber || ''}
                onChange={(e) => {
                  dispatch({ name: 'idNumber', value: e.target.value });
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ padding: 3, marginTop: 3 }}>
          <Typography variant="h6" sx={{ marginBottom: 3 }}>
            Notes
          </Typography>
          <Stack direction="column" spacing={3}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
              <TextField
                type="text"
                label="Notes"
                value={state?.notes || ''}
                onChange={(e) => {
                  dispatch({ name: 'notes', value: e.target.value });
                }}
                fullWidth
              />
            </Stack>
          </Stack>
        </Card>
      </>
    </div>
  );
};

export default PersonelForm;

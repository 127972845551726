/* eslint-disable react/prop-types */
import { Autocomplete, Button, Card, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getAgencyEquipment, getAgencyPersonel } from '../../../api/agency.api';
import { formatDate } from '../../../utils/formatTime';
import { createTripActivity } from '../../../api/trips.api';
import { Toast } from '../../../axios/Toast';

export default function CreateTripActivity({ setOpenState, trip }) {
  const [state, setState] = useState({ associatedTrip: trip });

  const { mutate: createTripActivityMtn } = useMutation(['createTripActivity'], () => createTripActivity(state), {
    onMutate: () => {
      Toast.loading('Loading, please wait...');
    },
    onError: () => {
      Toast.error('An error occurred');
    },
    onSuccess: (data) => {
      if (!data.success) {
        Toast.error(data.message);
      } else {
        Toast.success('Activity created');
        setOpenState(false);
      }
    },
  });

  const handleOnChange = ({ name, value }) => {
    setState({ ...state, [name]: value });
  };

  const { data: agencyPersonel } = useQuery(['getAgencyPersonel'], getAgencyPersonel);

  const { data: agencyEquipments } = useQuery(['getAgencyEquipment'], getAgencyEquipment);

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div>
          <div className="slider-header-title">Start trip activity</div>
          <div>{trip.tripTitle}</div>
        </div>
        <div className="slider-header-buttons">
          <Button variant="outlined" sx={{ marginRight: 2 }} onClick={() => setOpenState(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => createTripActivityMtn()}>
            Start
          </Button>
        </div>
      </div>
      <div className="slider-display-body">
        <Stack spacing={2}>
          <Card sx={{ padding: 3 }}>
            <Typography variant="h6" sx={{ marginBottom: 3 }}>
              Associated dates
            </Typography>
            <Autocomplete
              renderInput={(params) => <TextField {...params} label="Travel dates *" />}
              options={trip.tripCalendar || []}
              getOptionLabel={(option) =>
                option ? `${formatDate(option.startDate, 'dd MMM')} - ${formatDate(option.endDate, 'dd MMM')}` : ''
              }
              value={state?.associatedCalendar || ''}
              fullWidth
              onChange={(e, newValue) => handleOnChange({ name: 'associatedCalendar', value: newValue })}
            />
          </Card>

          <Card sx={{ padding: 3 }}>
            <Typography variant="h6" sx={{ marginBottom: 3 }}>
              Assign Personnels
            </Typography>
            <Autocomplete
              renderInput={(params) => <TextField {...params} label="Personels" />}
              options={agencyPersonel?.data || []}
              getOptionLabel={(option) => (option ? `${option.fullName} - ${option.operatorType.typeName}` : '')}
              value={state?.operators || []}
              fullWidth
              onChange={(e, newValue) => handleOnChange({ name: 'operators', value: newValue })}
              multiple
            />
          </Card>

          <Card sx={{ padding: 3 }}>
            <Typography variant="h6" sx={{ marginBottom: 3 }}>
              Assign Equipments
            </Typography>
            <Autocomplete
              renderInput={(params) => <TextField {...params} label="Equipments" />}
              options={agencyEquipments?.data || []}
              getOptionLabel={(option) =>
                option ? `${option.equipmentIdentifier} - ${option.equipmentType.typeName}` : ''
              }
              value={state?.equipments || []}
              fullWidth
              onChange={(e, newValue) => handleOnChange({ name: 'equipments', value: newValue })}
              multiple
            />
          </Card>

          <Card sx={{ padding: 3 }}>
            <Typography variant="h6" sx={{ marginBottom: 3 }}>
              Notes
            </Typography>
            <Stack direction="column" spacing={3}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                <TextField
                  type="text"
                  label="Notes"
                  value={state?.notes || ''}
                  onChange={(e) => {
                    handleOnChange({ name: 'notes', value: e.target.value });
                  }}
                  fullWidth
                />
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </div>
    </div>
  );
}

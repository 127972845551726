import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layout/dashboard';
import LoadOnlyLayout from './layout/LoadOnlyLayout';
//
import Login from './pages/login/Login';
import NotFound from './pages/404/Page404';
import Register from './pages/register/Register';
import DashboardApp from './pages/dashboard/DashboardApp';
import VerifyEmail from './pages/verify.email/VerifyEmail';
import Business from './pages/create.business/Business';
import AgencyCreate from './pages/create.agency/CreateAgency';
import AgencyTrips from './pages/trips/AgencyTrips';
import User from './pages/users/User';
import ForgetPassword from './pages/forget.password/ForgetPassword';
import ResetPassword from './pages/reset.password/ResetPassword';
import ProtectedRoute from './components/protected.route/ProtectedRoute';
import AgencyBookings from './pages/bookings/AgencyBookings';
import UserManagement from './pages/user.management/UserManagement';
import Marketplace from './pages/marketplace/Marketplace';
import AgencyProfile from './pages/agency.profile/AgencyProfile';
import AgencyOperatorsTabs from './pages/personel/AgencyOperatorsTabs';
import TripActivities from './pages/activities/TripActivities';
import SettlementsPage from './pages/settlements/Settlements';
import BookingReports from './pages/booking.reports/BookingReportsTmp';

export default function Router() {
  return useRoutes([
    {
      path: '/agent',
      element: <DashboardLayout />,
      children: [
        { path: 'dashboard', element: <ProtectedRoute component={<DashboardApp />} /> },
        { path: 'trips', element: <ProtectedRoute component={<AgencyTrips />} /> },
        { path: 'bookings', element: <ProtectedRoute component={<AgencyBookings />} /> },
        { path: 'user-and-roles', element: <ProtectedRoute component={<UserManagement />} /> },
        { path: 'user', element: <ProtectedRoute component={<User />} /> },
        { path: 'marketplace', element: <ProtectedRoute component={<Marketplace />} /> },
        { path: 'settings', element: <ProtectedRoute component={<AgencyProfile />} /> },
        { path: 'operators', element: <ProtectedRoute component={<AgencyOperatorsTabs />} /> },
        { path: 'activities', element: <ProtectedRoute component={<TripActivities />} /> },
        { path: 'settlements', element: <ProtectedRoute component={<SettlementsPage />} /> },
        { path: 'booking-reports', element: <ProtectedRoute component={<BookingReports />} /> },
      ],
    },

    // Auth
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },

    {
      path: 'verify',
      element: <VerifyEmail />,
    },

    {
      path: 'forget-password',
      element: <ForgetPassword />,
    },

    {
      path: 'reset-password',
      element: <ResetPassword />,
    },

    // Home
    {
      path: '/',
      element: <LoadOnlyLayout />,
    },
    {
      path: '/agency/create',
      element: <ProtectedRoute component={<AgencyCreate />} />,
    },

    // Business
    {
      path: '/business/create',
      element: <ProtectedRoute component={<Business />} />,
    },

    {
      path: '404',
      element: <NotFound />,
    },
    {
      path: '*',
      element: <Navigate to="/404" />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}

import {
  Avatar,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import { getTripsInMarketplace } from '../../api/trips.api';
import ModalDialog from '../../components/modal/ModalDialog';
import Scrollbar from '../../components/Scrollbar';
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { getCookie, setCookie } from '../../utils/cookies';
import Page from '../../components/Page';
import { enrollMarketPlaceOnPrem, enrollToMarketPlaceSaas, getAgencyDetails, updateAgency } from '../../api/agency.api';
import { Toast } from '../../axios/Toast';

const TABLE_HEADS = [
  { label: 'DESTINATION' },
  { label: 'TITLE' },
  { label: 'PACKAGES' },
  { label: 'CATEGORIES' },
  { label: 'STATUS' },
  { label: 'ACTIONS' },
];

export default function Marketplace() {
  const { agencyId, hasMarketplace } = getCookie('UserInfo');

  const [page, setPage] = useState(0);
  const [filterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filteredTrips, setFilteredTrips] = useState([]);
  const [nothingFound, setNothingFound] = useState(false);
  const [tripsList, setTripsList] = useState([]);
  const [selectedTrip, setSelectedTrip] = useState({});
  const [postedDialogOpen, setPostedDialogOpen] = useState(false);

  const { refetch } = useQuery(['getAgencyTripsMktplace'], getTripsInMarketplace, {
    onSuccess: (data) => {
      setTripsList(data.data || []);
      setFilteredTrips(data.data || []);
      setNothingFound(data.data.length === 0);
    },
  });

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tripsList.length) : 0;

  const { data: agencyDetails } = useQuery(['getAgencyDetails'], () => getAgencyDetails(agencyId), {
    enabled: process.env.REACT_APP_CLIENT_NAME === 'AGENCY-PORTAL',
  });

  const { mutate: removeFromMarketplaceSaas } = useMutation(
    ['removeFromMPSaas'],
    () => removeFromMarketplaceSaas(selectedTrip.id),
    {
      onSuccess: (response) => {
        if (response.success) {
          if (process.env.REACT_APP_CLIENT_NAME === 'AGENCY-PORTAL-SAAS') {
            Toast.success(response.message);
          } else {
            // OnPrem App - Also remove from main marketplace
            removeFromMarketplaceOnPrem();
          }
        } else {
          Toast.error(response.message);
        }
        refetch();
      },
    }
  );

  const { mutate: removeFromMarketplaceOnPrem } = useMutation(
    ['removeFromMPOnprem'],
    () => removeFromMarketplaceOnPrem(selectedTrip.marketPlaceId),
    {
      onSuccess: (response) => {
        if (response.success) {
          Toast.success(response.message);
        } else {
          Toast.error(response.message);
        }
      },
    }
  );

  const { mutate: updateAgencyDetailsMutation } = useMutation(['updateAgency'], (data) => updateAgency(data), {
    onSuccess: (response) => {
      if (response.success) {
        const uInfo = getCookie('UserInfo');
        setCookie('UserInfo', { ...uInfo, hasMarketplace: true });
        Toast.success(response.message);
        window.location.reload();
      } else {
        Toast.error('Unable to update enrollment status');
      }
    },
    onError: () => {
      Toast.error('An error occurred.');
    },
  });

  const { mutate: enrollMarketPlaceOnPremMutation } = useMutation(
    ['enrollMPOnPrem'],
    (data) => enrollMarketPlaceOnPrem(data),
    {
      onSuccess: (response) => {
        if (response.success) {
          const detailsCp = { ...agencyDetails };
          detailsCp.data.hasMarketplace = true;
          detailsCp.data.marketPlaceId = response.data.marketplaceId;
          updateAgencyDetailsMutation(detailsCp.data);
        } else {
          Toast.error('Unable to enroll to marketplace');
        }
      },
      onError: () => {
        Toast.error('An error occurred.');
      },
    }
  );

  const { mutate: enrollMarketPlaceSaasMutation } = useMutation(['enrollMPSaas'], enrollToMarketPlaceSaas, {
    onSuccess: (response) => {
      if (response.success) {
        Toast.success(response.message);
        const uInfo = getCookie('UserInfo');
        setCookie('UserInfo', { ...uInfo, hasMarketplace: true });
        Toast.success(response.message);
        window.location.reload();
      } else {
        Toast.error('Unable to enroll to marketplace');
      }
    },
    onError: () => {
      Toast.error('An error occurred.');
    },
  });

  const enrollToMarketPlace = () => {
    Toast.loading('Please wait..');
    if (process.env.REACT_APP_CLIENT_NAME === 'AGENCY-PORTAL-SAAS') {
      enrollMarketPlaceSaasMutation();
    } else {
      enrollMarketPlaceOnPremMutation(agencyDetails?.data);
    }
  };

  const handleRemoveFromMp = () => {
    Toast.loading('Please wait');
    if (process.env.REACT_APP_CLIENT_NAME === 'AGENCY-PORTAL-SAAS') {
      removeFromMarketplaceSaas();
    } else {
      removeFromMarketplaceOnPrem();
    }
  };

  return (
    <Page title="Marketplace">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          {hasMarketplace && (
            <Typography variant="h4" gutterBottom>
              Trips posted to the marketplace
            </Typography>
          )}
        </Stack>

        {!hasMarketplace && (
          <Stack direction="column" alignItems="center" justifyContent="center" spacing={3}>
            <Typography variant="h6">You have not enrolled to Travena Marketplace</Typography>
            <Typography maxWidth={700} textAlign="center">
              Enrolling enables you to post your trips to Travena.io, and the Travena APP where you can leverage our
              large traffic to view your offers.{' '}
            </Typography>
            <Button variant="contained" onClick={() => enrollToMarketPlace()}>
              Enroll Now (Free)
            </Button>
          </Stack>
        )}

        <Card>
          {/* <OutlinedInput
              value={filterName}
              onChange={(e) => {
                setFilterName(e.target.value);
              }}
              sx={{
                margin: 2,
              }}
              placeholder="Search..."
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-outline" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
            /> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: '#00ab66cc',
                  }}
                >
                  <TableRow>
                    {TABLE_HEADS.map((header) => (
                      <TableCell key={header.label}>{header.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredTrips.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const {
                      id,
                      destinationName,
                      tripTitle,
                      tripCategories,
                      tripCostPackages,
                      tripStatus,
                      featureImage,
                      inMarketplace,
                    } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell component="th" scope="row">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={destinationName} src={featureImage} />
                            <Typography variant="subtitle2" noWrap>
                              {destinationName}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{tripTitle}</TableCell>
                        <TableCell align="left">{tripCostPackages.length || 0}</TableCell>
                        <TableCell align="left">{tripCategories.join(', ')}</TableCell>
                        <TableCell align="left">
                          <Label variant="ghost" color={(tripStatus === 'INACTIVE' && 'error') || 'success'}>
                            {sentenceCase(tripStatus)}
                          </Label>
                        </TableCell>

                        <TableCell align="left">
                          {hasMarketplace && inMarketplace && (
                            <Iconify
                              onClick={() => {
                                setSelectedTrip(row);
                                setPostedDialogOpen(true);
                              }}
                              icon="mdi:marketplace"
                              width={30}
                              height={30}
                              sx={{
                                color: '#8db600',
                                marginRight: 2,
                              }}
                              title="Posted to marketplace"
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {nothingFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={tripsList.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <ModalDialog
        isOpen={postedDialogOpen}
        onCancel={() => setPostedDialogOpen(false)}
        onConfirm={() => {
          handleRemoveFromMp();
        }}
        cancelText={'Cancel'}
        confirmText={'Remove'}
        dialogContent={`This trip is already posted to the marketplace: ${selectedTrip.tripTitle}`}
        dialogTitle={'Remove from marketplace'}
      />
    </Page>
  );
}

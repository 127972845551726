/* eslint-disable react/prop-types */
import { Button } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useReducer } from 'react';
import { Toast } from '../../../axios/Toast';
import { getTrip, updateTrip } from '../../../api/trips.api';
import tripFormReducer from '../tripForm/tripFormReducer';
import TripForm from '../tripForm/TripForm';

export default function TripDetails({ setOpenState, trip, refetch }) {
  const [state, dispatch] = useReducer(tripFormReducer, { formData: new FormData() });

  const putTrip = useMutation(['updateTrip'], () => updateTrip(state.formData, state.id), {
    onMutate: () => {
      Toast.loading('Loading, please wait...');
    },
    onError: () => {
      Toast.error('An error occurred');
    },
    onSuccess: (data) => {
      if (!data.success) {
        Toast.error(data.message);
      } else {
        Toast.success('Trip updated');
        refetch();
        setOpenState(false);
      }
    },
  });

  const { data: tripDetails } = useQuery(['getTripDetails'], () => getTrip(trip.id), {
    onSuccess: (response) => {
      const { data } = response;
      dispatch({ name: 'setCurrent', value: { ...data, formData: new FormData() } });
      dispatch({ name: 'agency', value: JSON.stringify(data.agency) });
      dispatch({ name: 'destinationName', value: data.destinationName });
      dispatch({ name: 'destinationCountry', value: data.destinationCountry });
      dispatch({ name: 'tripTitle', value: data.tripTitle });
      dispatch({ name: 'allowPartialBooking', value: data.allowPartialBooking });
      dispatch({ name: 'tripAvailableSlots', value: data.tripAvailableSlots });
      dispatch({ name: 'tripStatus', value: data.tripStatus });
      dispatch({ name: 'otherInfo', value: data.otherInfo });
      dispatch({ name: 'tripCalendar', value: data.tripCalendar, stringify: true });
      dispatch({ name: 'tripCategories', value: data.tripCategories, stringify: true });
      dispatch({ name: 'tripCostPackages', value: data.tripCostPackages, stringify: true });
      dispatch({ name: 'tripDescription', value: data.tripDescription });
      dispatch({ name: 'tripExclusions', value: data.tripExclusions, stringify: true });
      dispatch({ name: 'tripIteneraries', value: data.tripIteneraries, stringify: true });
      dispatch({ name: 'tripServices', value: data.tripServices, stringify: true });
      dispatch({ name: 'destinationImagery', value: data.destinationImagery, stringify: true });
      dispatch({ name: 'featureImage', value: data.featureImage });
      dispatch({ name: 'id', value: data.id });
    },
  });

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div>
          <div className="slider-header-title">Trip Details</div>
          <div>{state.tripTitle}</div>
        </div>
        <div className="slider-header-buttons">
          <Button variant="outlined" sx={{ marginRight: 2 }} onClick={() => setOpenState(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => putTrip.mutate()}>
            Save
          </Button>
        </div>
      </div>
      <div className="slider-display-body">
        {tripDetails && <TripForm state={tripDetails?.data} dispatch={dispatch} />}
      </div>
    </div>
  );
}

/* eslint-disable react/prop-types */
import {
  Avatar,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import Iconify from '../../../components/Iconify';
import SearchNotFound from '../../../components/SearchNotFound';
import Scrollbar from '../../../components/Scrollbar';
import { getAgencyRoles } from '../../../api/agency.api';
import pallete from '../../../theme/palette';
import SliderModal from '../../../components/slider-modal/SliderModal';
import NewRole from './newRole/NewRole';
import RoleDetails from './roleDetails/RoleDetails';

const AgencyRoles = ({ newRoleModalOpen, setNewRoleModalOpen }) => {
  const TABLE_HEADS = [{ label: 'ROLE NAME' }, { label: 'PERMISSIONS' }, { label: 'ACTIONS' }];

  const [page, setPage] = useState(0);

  const [filterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [filteredRoles, setFilteredRoles] = useState([]);

  const [nothingFound, setNothingFound] = useState(false);

  const [rolesList, setRolesList] = useState([]);

  const [roleDetailsOpen, setRoleDetailsOpen] = useState(false);

  const [selectedRole, setSelectedRole] = useState({});

  const { refetch } = useQuery(['getAgencyRoles'], () => getAgencyRoles(), {
    onSuccess: (data) => {
      setRolesList(data.data || []);
      setFilteredRoles(data.data || []);
      setNothingFound(data?.data.length === 0);
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rolesList.length) : 0;

  return (
    <Card>
      {/* <OutlinedInput
                value={filterName}
                onChange={(e) => {
                  setFilterName(e.target.value);
                }}
                sx={{
                  margin: 2,
                }}
                placeholder="Search..."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
              /> */}

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead
              sx={{
                backgroundColor: '#B2BEB5',
              }}
            >
              <TableRow>
                {TABLE_HEADS.map((header) => (
                  <TableCell key={header.label}>{header.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRoles?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const { id, name, permissions } = row;

                return (
                  <TableRow hover key={id} tabIndex={-1} role="checkbox">
                    <TableCell component="th" scope="row">
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar alt={name} />
                        <Typography variant="subtitle2" noWrap>
                          {name}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="left">{permissions.length} Permissions</TableCell>

                    <TableCell align="left">
                      <Iconify
                        onClick={() => {
                          setSelectedRole(row);
                          setRoleDetailsOpen(true);
                        }}
                        icon="eva:info-outline"
                        width={30}
                        height={30}
                        sx={{
                          color: pallete.success.main,
                          marginRight: 2,
                        }}
                      />

                      <Iconify
                        onClick={() => {
                          setSelectedRole(row);
                          setRoleDetailsOpen(true);
                        }}
                        icon="eva:trash-outline"
                        width={30}
                        height={30}
                        sx={{
                          color: pallete.error.main,
                          marginRight: 2,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

            {nothingFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rolesList.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <SliderModal openState={newRoleModalOpen} setOpenState={setNewRoleModalOpen}>
        <NewRole setOpenState={setNewRoleModalOpen} refetchRoles={refetch} />
      </SliderModal>

      <SliderModal openState={roleDetailsOpen} setOpenState={setRoleDetailsOpen}>
        <RoleDetails setOpenState={setRoleDetailsOpen} refetchRoles={refetch} roleDetails={selectedRole} />
      </SliderModal>
    </Card>
  );
};

export default AgencyRoles;

import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Toast } from '../../../axios/Toast';
import { createAgencyEquipment } from '../../../api/agency.api';
import EquipmentForm from '../equipmentForm/EquipmentForm';
// eslint-disable-next-line react/prop-types
const NewEquipment = ({ setOpenState, refetch }) => {
  const [state, setState] = useState({});

  const dispatch = ({ name, value }) => {
    setState({ ...state, [name]: value });
  };

  const createEquipmentMtn = useMutation(['createEquipmentMtn'], () => createAgencyEquipment(state), {
    onSettled: () => {
      refetch();
      setOpenState(false);
    },
    onSuccess: (response) => {
      if (response.success) {
        Toast.success(response.message);
        refetch();
        setOpenState(false);
      } else {
        Toast.error(response.message);
      }
    },
    onError: () => {
      Toast.error('Error occurred');
    },
  });

  const onSave = () => {
    Toast.loading('Please wait..');
    createEquipmentMtn.mutate();
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">New Equipment</div>
        <div className="slider-header-title">{state.tripName}</div>
        <div className="slider-header-buttons">
          <Button variant="outlined" sx={{ marginRight: 2 }} onClick={() => setOpenState(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => onSave()}>
            Save
          </Button>
        </div>
      </div>
      <div className="slider-display-body">
        <EquipmentForm state={state} dispatch={dispatch} />
      </div>
    </div>
  );
};

export default NewEquipment;

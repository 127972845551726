import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material';
// components
import { getCookie, inValidateCookies } from '../../utils/cookies';
import MenuPopover from '../../components/MenuPopover';
// mocks_
import account from '../../_mock/account';
import Iconify from '../../components/Iconify';

const MENU_OPTIONS = [
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    linkTo: '#',
  },
];

export default function AccountPopover() {
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(null);

  const { name, role } = getCookie('UserInfo');

  const navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (action) => {
    if (action === 'logout') {
      inValidateCookies(true);
      navigate('/login');
    }
    setOpen(null);
  };

  return (
    <>
      <Stack direction="row" onClick={handleOpen}>
        <IconButton ref={anchorRef} onClick={handleOpen}>
          <Avatar src={account.photoURL} alt="photoURL" />
        </IconButton>

        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography variant="subtitle1" sx={{ color: '#00284F' }} noWrap>
            {name}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body2" sx={{ color: '#00284F' }} noWrap>
            {role?.toLowerCase()}
          </Typography>
          <Iconify sx={{color: '#00284F'}} icon="codicon:circle-filled" />
          </Stack>
        </Box>
      </Stack>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={() => handleClose(option.label)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={() => handleClose('logout')} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}

import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { Stack, FormControl, Select, InputLabel, MenuItem, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../../components/hook-form';
import { showToast } from '../../utils/showToast';
// components
import { getAgencyTypes, createAgency } from '../../api/agency.api';
import Label from '../../components/Label';

export default function AgencyCreateForm() {
  const navigate = useNavigate();

  const location = useLocation();

  const businessData = location.state;
  const formData = new FormData();

  const AgencySchema = Yup.object().shape({
    name: Yup.string().required('Agency name required'),
    contactPersonName: Yup.string().required('Contact Person is required'),
    officePhoneNumber: Yup.string().required('Office Number is Required'),
    agencyType: Yup.string().required('Please select an agency type'),
    traNumber: Yup.string().required('TRA Number is required'),
  });

  const defaultValues = {
    name: '',
    contactPersonName: '',
    officePhoneNumber: '',
    agencyType: 'Tours',
  };

  const methods = useForm({
    resolver: yupResolver(AgencySchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  // TODO  create Agency here
  const { data } = useQuery(['getAgencyTypes'], getAgencyTypes);

  // Create agency
  const { mutate: postCreateAgency, isLoading } = useMutation(createAgency, {
    onSuccess: (createAgencyResponse) => {
      if (createAgencyResponse.success) {
        showToast({ type: 'Success', message: createAgencyResponse.message });
        navigate('/login');
      } else {
        showToast({ type: 'error', message: createAgencyResponse.message });
      }
    },
  });

  const onSubmit = async (values) => {
    values = {
      ...values,
      businessName: businessData?.businessName,
      brsNumber: businessData?.brsNumber,
      officeLocationCity: businessData?.officeLocationBuilding,
      officeLocationHouseNumber: businessData?.officeLocationHouseNumber,
    };

    formData.set('agencyType', values.agencyType);
    formData.set('brsNumber', values.brsNumber);
    formData.set('businessName', values.businessName);
    formData.set('contactPersonName', values.contactPersonName);
    formData.set('name', values.name);
    formData.set('officeLocationCity', values.officeLocationCity);
    formData.set('officeLocationHouseNumber', values.officeLocationHouseNumber);
    formData.set('officePhoneNumber', values.officePhoneNumber);
    formData.set('traNumber', values.traNumber);

    postCreateAgency(formData);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="name" label="Agency Name" />
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="contactPersonName" label="Contact person name" />
        </Stack>

        <RHFTextField name="officePhoneNumber" label="Contact person phone number" />

        <RHFTextField name="traNumber" label="TRA Certificate Number" />

        <Stack>
          <Label>BRS Certificate (or Incoporation) (pdf or image)</Label>
          <TextField
            name="brsCertificate"
            type="file"
            required
            onChange={(e) => {
              if (e.target.files) {
                formData.set('brsCertificate', e.target.files[0]);
              }
            }}
            inputProps={{ accept: ['application/pdf', 'image/*'] }}
          />
        </Stack>

        <Stack>
          <Label>TRA Certificate (pdf or image)</Label>
          <TextField
            name="traCertificate"
            type="file"
            required
            onChange={(e) => {
              if (e.target.files) {
                formData.set('traCertificate', e.target.files[0]);
              }
            }}
            inputProps={{ accept: ['application/pdf', 'image/*'] }}
          />
        </Stack>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Agency Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Agency Types"
            {...methods.register('agencyType')}
          >
            {data &&
              data.map((agencyType) => (
                <MenuItem key={agencyType} value={agencyType}>
                  {agencyType}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
          Next
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}

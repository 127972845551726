import { useState } from 'react';
import { Button, Container, Stack } from '@mui/material';
import Page from '../../components/Page';
import AgencyUsers from './users/AgencyUsers';
import AgencyRoles from './roles/AgencyRoles';
import Iconify from '../../components/Iconify';

const UserManagement = () => {
  const [openTab, setOpenTab] = useState('Users');

  const [newUserModalOpen, setNewUserModalOpen] = useState(false);
  const [newRoleModalOpen, setNewRoleModalOpen] = useState(false);

  return (
    <Page title="Users">
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ marginBottom: 20 }}>
          <Stack direction="row">
            <Button
              onClick={() => setOpenTab('Users')}
              style={{
                height: 50,
                width: 150,
                color: openTab === 'Users' ? '' : '#000000',
                borderBottom: openTab === 'Users' ? '5px solid green' : '',
                borderRadius: 0,
                marginRight: 10,
              }}
            >
              Manage Users
            </Button>
            <Button
              onClick={() => setOpenTab('Roles')}
              style={{
                height: 50,
                width: 150,
                color: openTab === 'Roles' ? '' : '#000000',
                borderBottom: openTab === 'Roles' ? '5px solid green' : '',
                borderRadius: 0,
                marginRight: 10,
              }}
            >
              Manage Roles
            </Button>
          </Stack>

          {openTab === 'Users' ? (
            <Button
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => setNewUserModalOpen(true)}
              variant="contained"
            >
              New User
            </Button>
          ) : (
            <Button
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => setNewRoleModalOpen(true)}
              variant="contained"
            >
              New Role
            </Button>
          )}
        </Stack>

        {openTab === 'Users' ? (
          <AgencyUsers newUserModalOpen={newUserModalOpen} setNewUserModalOpen={setNewUserModalOpen} />
        ) : (
          <AgencyRoles newRoleModalOpen={newRoleModalOpen} setNewRoleModalOpen={setNewRoleModalOpen} />
        )}
      </Container>
    </Page>
  );
};

export default UserManagement;

import { format, formatDistanceToNow } from 'date-fns';

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function formatDate(date, dateFormat){
  if(!date) { return ""}
  let mDateFormat = dateFormat
  if(!dateFormat){
    mDateFormat = "dd MMM, yy"
  }
  return format(new Date(date), mDateFormat)
}

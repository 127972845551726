/* eslint-disable react/prop-types */
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Slide } from '@mui/material';
import React from 'react';

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

const ModalDialog = ({ isOpen, dialogContent, onCancel, onConfirm, cancelText, confirmText, dialogTitle }) => (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      onClose={() => {
        onCancel()
        return true
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{dialogTitle || "?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{dialogContent}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onCancel()}>
          {cancelText || ''}
        </Button>
        <Button variant="contained" onClick={() => onConfirm()}>
          {confirmText || ''}
        </Button>
      </DialogActions>
    </Dialog>
  );

export default ModalDialog;

export const agencyEndPoints = {
  getAgencies: `/agency`,
  getAgencyDetails: (agencyId) => `/agency/${agencyId}`,
  getAgencyTypes: '/agency/agencyTypes',
  createAgency: '/agency/new',
  updateAgency: '/agency/update',
  createAgencyUser: (agencyId) => `/agency/users/add/${agencyId}`,
  getAgencyUsers: (agencyId) => `/agency/users/${agencyId}`,
  getAgencyRoles: (agencyId) => `/agency/roles/${agencyId}`,
  createAgencyRole: (agencyId) => `/agency/roles/add/${agencyId}`,
  updateAgencyRole: `/agency/roles/update`,
  enrollMarketPlaceSaas: (agencyId) => `/agency/enrollMarketplaceSaas/${agencyId}`,
  enrollMarketPlaceOnPrem: '/agency/enrollMarketPlaceOnPrem',
  getDashboard: (agencyId) => `/agency/dashboard/${agencyId}`,
  updateProfile: (agencyId) => `/agency/updateProfile/${agencyId}`,
  agencyOperators: (agencyId) => `/agency/operators/${agencyId}`,
  operatorTypes: (agencyId) => `/agency/operatorTypes/${agencyId}`,
  equipmentTypes: (agencyId) => `/agency/equipmentTypes/${agencyId}`,
  agencyEquipments: (agencyId) => `/agency/equipments/${agencyId}`,
  settlementAcct: (agencyId) => `/agency/settlementAcct/${agencyId}`,
  settlements: (agencyId) => `/settlements/get/${agencyId}`,
  bankNames: `/settlements/bankNames`
};

/* eslint-disable react/prop-types */
import { Autocomplete, Card, Grid, Stack, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getAgencyTrips } from '../../../api/trips.api';
import { formatDate } from '../../../utils/formatTime';

// eslint-disable-next-line react/prop-types
const BookingForm = ({ state, dispatch, submitted }) => {
  const { data: agencyTrips } = useQuery(['getAgencyTrips'], getAgencyTrips, {
    initialData: [],
  });

  const [tripCostPackageOptions, setTripCostPackageOptions] = useState([]);
  const [tripCalendarOptions, setTripCalendarOptions] = useState([]);

  const [selectedTrip, setSelectedTrip] = useState(state?.trip);
  const [selectedPackage, setSelectedPackage] = useState(state?.tripCostPackage);
  const [selectedDate, setSelectedDate] = useState(state?.tripCalendar);

  const onTripSelected = (trip) => {
    setTripCostPackageOptions(trip?.tripCostPackages);
    setTripCalendarOptions(trip?.tripCalendar);
    setSelectedTrip(trip);
    dispatch({ name: 'tripId', value: trip.id });
    dispatch({ name: 'tripTitle', value: trip.tripTitle });
    setSelectedDate('');
    setSelectedPackage('');
    dispatch({ name: 'costPackageId', value: '' });
    dispatch({ name: 'calendarId', value: '' });
  };

  const onPackageSelected = (mPackage) => {
    setSelectedPackage(mPackage);
    dispatch({ name: 'costPackageId', value: mPackage.id });
  };

  const onCalenderSelected = (calendar) => {
    setSelectedDate(calendar);
    dispatch({ name: 'calendarId', value: calendar.id });
  };

  useEffect(() => {
    dispatch({ name: 'totalCost', value: Number(selectedPackage?.packageCost * state?.numberOfPeople) });
    dispatch({ name: 'amountPaid', value: Number(selectedPackage?.packageCost * state?.numberOfPeople) });
  }, [selectedPackage?.packageCost, dispatch, state?.numberOfPeople]);

  return (
    <div>
      <>
        <Card sx={{ padding: 3 }}>
          <Typography variant="h6" sx={{ marginBottom: 3 }}>
            Customer Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextField
                type="text"
                label="Full name *"
                value={state?.bookingContact?.fullName || ''}
                onChange={(e) => {
                  dispatch({ category: 'bookingContact', name: 'fullName', value: e.target.value });
                }}
                fullWidth
                error={submitted && !state?.bookingContact?.fullName}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                type="text"
                label="Phone number *"
                value={state?.bookingContact?.phoneNumber || ''}
                onChange={(e) => {
                  dispatch({ category: 'bookingContact', name: 'phoneNumber', value: e.target.value });
                }}
                fullWidth
                error={submitted && !state?.bookingContact?.phoneNumber}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                type="text"
                label="Email *"
                value={state?.bookingContact?.email || ''}
                onChange={(e) => {
                  dispatch({ category: 'bookingContact', name: 'email', value: e.target.value });
                }}
                fullWidth
                error={submitted && !state?.bookingContact?.email}
              />
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ padding: 3, marginTop: 3 }}>
          <Typography variant="h6" sx={{ marginBottom: 3 }}>
            Trip Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Autocomplete
                renderInput={(params) => <TextField {...params} label="Trip *" error={submitted && !state?.tripId} />}
                options={agencyTrips?.data || []}
                getOptionLabel={(option) => option.tripTitle || ''}
                value={selectedTrip || ''}
                fullWidth
                onChange={(e, newValue) => onTripSelected(newValue)}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                renderInput={(params) => <TextField {...params} label="Cost package *" error={submitted && !state?.costPackageId}  />}
                options={tripCostPackageOptions || []}
                getOptionLabel={(option) => (option ? `${option.packageName} @Ksh ${option.packageCost}` : '')}
                value={selectedPackage || ''}
                fullWidth
                onChange={(_, newValue) => onPackageSelected(newValue)}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                renderInput={(params) => <TextField {...params} label="Travel dates *" error={submitted && !state?.calendarId} />}
                options={tripCalendarOptions || []}
                getOptionLabel={(option) =>
                  option ? `${formatDate(option.startDate, 'dd MMM')} - ${formatDate(option.endDate, 'dd MMM')}` : ''
                }
                value={selectedDate || ''}
                fullWidth
                onChange={(e, newValue) => onCalenderSelected(newValue)}
              />
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ padding: 3, marginTop: 3 }}>
          <Typography variant="h6" sx={{ marginBottom: 3 }}>
            Booking Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextField
                type="number"
                label="Number of adults *"
                value={state?.numberOfPeople || ''}
                onChange={(e) => {
                  dispatch({ name: 'numberOfPeople', value: e.target.value });
                }}
                fullWidth
                error={submitted && !state?.numberOfPeople}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                type="number"
                label="Number of kids"
                value={state?.numberOfKids || ''}
                onChange={(e) => {
                  dispatch({ name: 'numberOfKids', value: e.target.value });
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                renderInput={(params) => <TextField {...params} label="Booking type" />}
                options={['Partial', 'Full']}
                value={state?.bookingType || 'Full'}
                fullWidth
                onChange={(e, newValue) => dispatch({ name: 'bookingType', value: newValue })}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                type="number"
                label="Total Cost"
                value={Number(selectedPackage?.packageCost * state?.numberOfPeople) || ''}
                disabled
                onChange={(e) => {
                  dispatch({ name: 'totalCost', value: e.target.value });
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ padding: 3, marginTop: 3 }}>
          <Typography variant="h6" sx={{ marginBottom: 3 }}>
            Payment Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Autocomplete
                renderInput={(params) => <TextField {...params} label="Payment status" />}
                options={['PAID', 'UNPAID']}
                value={state?.paymentStatus || 'PAID'}
                fullWidth
                onChange={(e, newValue) => dispatch({ name: 'paymentStatus', value: newValue })}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                type="number"
                label="Amount paid *"
                value={state?.amountPaid || ''}
                onChange={(e) => {
                  dispatch({ name: 'amountPaid', value: e.target.value });
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                type="text"
                label="Payment reference"
                value={state?.paymentReference || ''}
                onChange={(e) => {
                  dispatch({ name: 'paymentReference', value: e.target.value });
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ padding: 3, marginTop: 3 }}>
          <Typography variant="h6" sx={{ marginBottom: 3 }}>
            Notes
          </Typography>
          <Stack direction="column" spacing={3}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
              <TextField
                type="text"
                label="Notes"
                value={state?.customerNotes || ''}
                onChange={(e) => {
                  dispatch({ name: 'customerNotes', value: e.target.value });
                }}
                fullWidth
              />
            </Stack>
          </Stack>
        </Card>
      </>
    </div>
  );
};

export default BookingForm;

import axios from 'axios';
import { getCookie } from '../utils/cookies';

const PROD_URL = process.env.REACT_APP_TRAVENA_API;
const DEV_URL = process.env.REACT_APP_TRAVENA_API_DEV;

const BASE_URL = process.env.REACT_APP_ENVIRONMENT === 'development' ? DEV_URL : PROD_URL;

const axiosTravenaApiClient = axios.create({
  baseURL: BASE_URL,
});

const customErrorConfig = (error) => Promise.reject(error);
// Do something with request error

axiosTravenaApiClient.interceptors.request.use(async (config) => {
  const userData = getCookie('UserInfo');

  const authToken = userData ? userData.accessToken : '';

  config.headers = {
    authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json',
    XClient: process.env.REACT_APP_CLIENT_NAME,
    XClientUrl: window.location.host,
  };

  return config;
}, customErrorConfig);

axiosTravenaApiClient.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      window.location = `${window.location.origin}/login`;
    }
    return response;
  },
  (error) => Promise.reject(error)
);

export default axiosTravenaApiClient;

/* eslint-disable react/prop-types */
import {
  Avatar,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { sentenceCase } from 'change-case';
import Iconify from '../../../components/Iconify';
import SearchNotFound from '../../../components/SearchNotFound';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import { getAgencyUsers } from '../../../api/agency.api';
import pallete from '../../../theme/palette';
import SliderModal from '../../../components/slider-modal/SliderModal';
import NewUser from './newUser/NewUser';
import UserDetails from './userDetails/UserDetails';

const AgencyUsers = ({ newUserModalOpen, setNewUserModalOpen }) => {
  const TABLE_HEADS = [
    { label: 'NAME' },
    { label: 'EMAIL' },
    { label: 'PHONE NUMBER' },
    { label: 'ROLE' },
    { label: 'STATUS' },
    { label: 'ACTIONS' },
  ];

  const [page, setPage] = useState(0);
  const [filterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [nothingFound, setNothingFound] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [userDetailsOpen, setUserDetailsOpen] = useState(false);

  const { refetch: refetchUsers } = useQuery(['getAgencyUsers'], () => getAgencyUsers(), {
    onSuccess: (data) => {
      setUsersList(data.data || []);
      setFilteredUsers(data.data || []);
      setNothingFound(data?.data.length === 0);
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersList.length) : 0;

  return (
    <Card>
      {/* <OutlinedInput
              value={filterName}
              onChange={(e) => {
                setFilterName(e.target.value);
              }}
              sx={{
                margin: 2,
              }}
              placeholder="Search..."
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
            /> */}

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead
              sx={{
                backgroundColor: '#00ab66cc',
              }}
            >
              <TableRow>
                {TABLE_HEADS.map((header) => (
                  <TableCell key={header.label}>{header.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const { userId, userProfile, agencyRole, userStatus } = row;

                return (
                  <TableRow hover key={userId} tabIndex={-1} role="checkbox">
                    <TableCell component="th" scope="row">
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar alt={userProfile?.fullName} src={userProfile.photoUrl} />
                        <Typography variant="subtitle2" noWrap>
                          {userProfile?.fullName}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="left">{userProfile?.email}</TableCell>
                    <TableCell align="left">{userProfile?.phoneNumber}</TableCell>
                    <TableCell align="left">{agencyRole?.name}</TableCell>
                    <TableCell align="left">
                      <Label variant="ghost" color={(userStatus === 'INACTIVE' && 'error') || 'success'}>
                        {sentenceCase(userStatus)}
                      </Label>
                    </TableCell>

                    <TableCell align="left">
                      <Iconify
                        onClick={() => {
                          setSelectedUser(row);
                          setUserDetailsOpen(true);
                        }}
                        icon="eva:info-outline"
                        width={30}
                        height={30}
                        sx={{
                          color: pallete.info.main,
                          marginRight: 2,
                        }}
                      />

                      {/* <Iconify
                        onClick={() => {
                          setSelectedUser(row);
                          setUserDetailsOpen(true);
                        }}
                        icon="eva:trash-outline"
                        width={30}
                        height={30}
                        sx={{
                          color: pallete.error.main,
                          marginRight: 2,
                        }}
                      /> */}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

            {nothingFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={usersList.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <SliderModal openState={newUserModalOpen} setOpenState={setNewUserModalOpen}>
        <NewUser setOpenState={setNewUserModalOpen} refetchUsers={refetchUsers} />
      </SliderModal>

      <SliderModal openState={userDetailsOpen} setOpenState={setUserDetailsOpen}>
        <UserDetails setOpenState={setUserDetailsOpen} refetchUsers={refetchUsers} userDetails={selectedUser} />
      </SliderModal>
    </Card>
  );
};

export default AgencyUsers;

import axiosTravenaApiClient from '../axios/AxiosTravenaApiClient';
import { getCookie } from '../utils/cookies';
import axiosClientFormData from '../axios/AxiosClientFormData';
import axiosClient from '../axios/AxiosClient';
import { tripEndpoints } from './trips.endpoints';

export const getFeaturedTrips = async () => {
  const results = await axiosClient.get(tripEndpoints.getFeatured);
  return results?.data;
};

export const getAllTrips = async () => {
  const results = await axiosClient.get(tripEndpoints.getAllTrips);
  return results?.data;
};

export const getAgencyTrips = async () => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.get(tripEndpoints.getAgencyTrips(agencyId));
  return results?.data;
};

export const getTripsInMarketplace = async () => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.get(tripEndpoints.getAgencyTripsMarketplace(agencyId, true));
  return results?.data;
};

export const getTrip = async (tripId) => {
  const results = await axiosClient.get(tripEndpoints.getTrip(tripId));
  return results?.data;
};

export const getTripPromotions = async () => {
  const results = await axiosClient.get(tripEndpoints.getTripPromotions);
  return results?.data;
};

export const getPromotedTrips = async (promotionId) => {
  const results = await axiosClient.get(tripEndpoints.getPromotedTrips(promotionId));
  return results?.data;
};

export const getTripCategories = async () => {
  const results = await axiosClient.get(tripEndpoints.getCategories);
  return results?.data;
};

export const createTrip = async (tripDetails) => {
  const results = await axiosClientFormData.post(tripEndpoints.postTrip, tripDetails);
  return results?.data;
};

export const updateTrip = async (tripDetails, tripId) => {
  const results = await axiosClientFormData.put(tripEndpoints.putTrip(tripId), tripDetails);
  return results?.data;
};

export const postToMarketplaceSaas = async (tripId) => {
  const results = await axiosClient.post(tripEndpoints.postToMarketplaceSaas(tripId));
  return results?.data;
};

export const postToMarketplaceOnPrem = async (tripDetails) => {
  const { marketPlaceId } = getCookie('UserInfo');
  const results = await axiosTravenaApiClient.post(tripEndpoints.postToMarketplaceOnPrem(marketPlaceId), tripDetails);
  return results?.data;
};

export const removeFromMarketplaceSaas = async (tripId) => {
  const results = await axiosClient.post(tripEndpoints.removeFromMarketplaceSaas(tripId));
  return results?.data;
};

export const removeFromMarketplaceOnPrem = async (marketPlaceId) => {
  const results = await axiosTravenaApiClient.post(tripEndpoints.removeFromMarketplaceOnPrem(marketPlaceId));
  return results?.data;
};

export const getAgencyBookings = async () => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.get(tripEndpoints.getAgencyTrips(agencyId));
  return results?.data;
};

export const deleteTrip = async (tripId) => {
  const results = await axiosClient.delete(tripEndpoints.deleteTrip(tripId));
  return results?.data;
};

export const createTripActivity = async (activityDetail) => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.post(tripEndpoints.tripActivity(agencyId), activityDetail);
  return results?.data;
};

export const getTripActivities = async () => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.get(tripEndpoints.tripActivity(agencyId));
  return results?.data;
};

import React, { useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  Grid,
  TextField,
  // OutlinedInput,
  // InputAdornment,
} from '@mui/material';
// components
import { useMutation, useQuery } from '@tanstack/react-query';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import pallete from '../../theme/palette';
import { createAgencyPersonelType, getAgencyPersonel, getPersonelType } from '../../api/agency.api';
import { showToast } from '../../utils/showToast';
import SliderModal from '../../components/slider-modal/SliderModal';
import NewPersonel from './newPersonel/NewPersonel';
import PersonelDetails from './personelDetails/PersonelDetail';

const TABLE_HEADS = [
  { label: 'NAME' },
  { label: 'PHONE NUMBER' },
  { label: 'ID NUMBER' },
  { label: 'DESIGNATION' },
  { label: 'ACTIONS' },
];

export default function AgencyPersonel() {
  const [page, setPage] = useState(0);
  const [filterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filteredPersonel, setFilteredPersonel] = useState([]);
  const [nothingFound, setNothingFound] = useState(false);
  const [personelList, setPersonelList] = useState([]);
  const [newPersonelOpen, setNewPersonelOpen] = useState(false);
  const [personDetailsOpen, setPersonDetailsOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState();
  const [newDesignationName, setNewDisgnationName] = useState('');
  const [newDesignationDesc, setNewDesignationDesc] = useState('');

  const { refetch: refetchPersonel } = useQuery(['getAgencyPersonel'], getAgencyPersonel, {
    onSuccess: (data) => {
      setPersonelList(data.data || []);
      setFilteredPersonel(data.data || []);
      setNothingFound(data.data.length === 0);
    },
  });

  const { data: personelTypes, refetch: refetchPersonelType } = useQuery(['getAgencyPersonelType'], getPersonelType, {
    initialData: {},
  });

  const { mutate: createDesignationMutation } = useMutation(
    ['createDesignation'],
    () => createAgencyPersonelType({ typeName: newDesignationName, typeDescription: newDesignationDesc }),
    {
      onSuccess: () => refetchPersonelType(),
    }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - personelList.length) : 0;

  return (
    <Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="h6" gutterBottom>
          Personels
        </Typography>
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:plus-outline" />}
          onClick={() => setNewPersonelOpen(true)}
        >
          New Personel
        </Button>
      </Stack>

      <Grid container spacing={2}>
        <Grid item sm={12} md={9}>
          <Card>
            <Scrollbar>
              <TableContainer>
                <Table>
                  <TableHead
                    sx={{
                      backgroundColor: '#00ab66cc',
                    }}
                  >
                    <TableRow>
                      {TABLE_HEADS.map((header) => (
                        <TableCell key={header.label}>{header.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredPersonel.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { fullName, phoneNumber, idNumber, operatorType } = row;

                      return (
                        <TableRow hover key={row.id} tabIndex={-1} role="checkbox">
                          <TableCell component="th" scope="row">
                            <Stack direction="column" spacing={2}>
                              {fullName}
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{phoneNumber}</TableCell>
                          <TableCell align="left">{idNumber}</TableCell>
                          <TableCell align="left">{operatorType?.typeName}</TableCell>
                          <TableCell align="left">
                            <Iconify
                              icon="eva:info-outline"
                              width={30}
                              height={30}
                              sx={{
                                color: pallete.info.main,
                                marginRight: 2,
                              }}
                              onClick={() => {
                                setSelectedPerson(row);
                                setPersonDetailsOpen(true);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {nothingFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={personelList.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Grid>

        <Grid item sm={12} md={3}>
          <Card sx={{ padding: 2 }}>
            <Stack spacing={1}>
              <Stack alignItems="center" justifyContent="flex-end" mb={3}>
                <Typography variant="h6" gutterBottom>
                  Designations
                </Typography>
              </Stack>

              {personelTypes?.data?.map((pType) => (
                <Stack
                  direction="row"
                  spacing={1}
                  key={pType.id}
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom="1px solid #c2c2c2"
                >
                  <Stack spacing={1}>
                    <Typography>{pType.typeName}</Typography>
                    <Typography variant="caption">{pType.typeDescription}</Typography>
                  </Stack>

                  <Iconify icon="ic:baseline-delete" />
                </Stack>
              ))}
            </Stack>
          </Card>

          <Card sx={{ padding: 2, marginTop: 2 }}>
            <Stack spacing={1}>
              <Typography paddingTop={3} textAlign="center">
                Create new designation
              </Typography>

              <TextField label="Designation name *" fullWidth onChange={(e) => setNewDisgnationName(e.target.value)} />
              <TextField label="Description" fullWidth onChange={(e) => setNewDesignationDesc(e.target.value)} />
              <Button
                onClick={() => {
                  if (newDesignationName) {
                    createDesignationMutation();
                  } else {
                    showToast({ type: 'error', message: 'Name is required' });
                  }
                }}
                variant="contained"
              >
                Save
              </Button>
            </Stack>
          </Card>
        </Grid>

        <SliderModal openState={newPersonelOpen} setOpenState={setNewPersonelOpen}>
          <NewPersonel setOpenState={setNewPersonelOpen} refetch={refetchPersonel} />
        </SliderModal>

        <SliderModal openState={personDetailsOpen} setOpenState={setPersonDetailsOpen}>
          <PersonelDetails
            setOpenState={setPersonDetailsOpen}
            refetch={refetchPersonel}
            personelDetails={selectedPerson}
          />
        </SliderModal>
      </Grid>
    </Stack>
  );
}

import React, { useRef, useState } from 'react';
import { Button, ButtonBase, Menu, Stack } from '@mui/material';
import { DateRangePicker } from 'react-date-range';
// Date Range Styles
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays } from 'date-fns';

// eslint-disable-next-line react/prop-types
export default function DateRangePickers({ onSelect, size }) {
  const [open, setOpen] = useState(false);

  const buttonRef = useRef();

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);

  return (
    <>
      <ButtonBase
        sx={{
          width: '100%',
          justifyContent: 'start',
          border: '1px solid #CCCCCC',
          height: size === 'small' ? '40px' : '56px',
          borderRadius: 1,
          paddingX: 2,
          fontWeight: '600',
          color: 'gray',
        }}
        onClick={() => setOpen(true)}
        ref={buttonRef}
      >
        Dates range
      </ButtonBase>

      <Menu open={open} onClose={() => setOpen(false)} anchorEl={buttonRef.current}>
        <Stack>
          <DateRangePicker
            showSelectionPreview
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            onChange={(item) => setState([item.selection])}
            direction="horizontal"
            color="#00AB66"
            rangeColors={['#00AB66']}
          />

          <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" paddingX={4}>
            <Button onClick={() => setOpen(false)} variant="outlined">
              Cancel
            </Button>
            <Button variant="contained" onClick={() => onSelect(state)}>
              Save
            </Button>
          </Stack>
        </Stack>
      </Menu>
    </>
  );
}

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../../utils/cookies';

const ProtectedRoute = ({ component }) => {
  const navigate = useNavigate();
  const userInfo = getCookie('UserInfo');

  const nowDate = +new Date() - (12 * 60 * 60 *1000);

  useEffect(() => {
    if (!userInfo || !nowDate > userInfo.expiresAt) {
        navigate('/login');
      }
  }, [navigate, nowDate, userInfo])

  return component
};

export default ProtectedRoute;

import axiosTravenaApiClient from '../axios/AxiosTravenaApiClient';
import { getCookie } from '../utils/cookies';
import axiosClient from '../axios/AxiosClient';
import { agencyEndPoints } from './agency.endpoints';
import axiosClientFormData from '../axios/AxiosClientFormData';

export const getAgencies = async () => {
  const results = await axiosClient.get(agencyEndPoints.getAgencies);
  return results?.data;
};

export const getAgencyDetails = async (agencyId) => {
  const results = await axiosClient.get(agencyEndPoints.getAgencyDetails(agencyId));
  return results?.data;
};

export const createAgency = async (agencyData) => {
  const results = await axiosClientFormData.post(agencyEndPoints.createAgency, agencyData);
  return results?.data;
};

export const updateAgency = async (agencyData) => {
  const results = await axiosClient.put(agencyEndPoints.updateAgency, agencyData);
  return results?.data;
};

export const updateAgencyProfile = async (agencyData) => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClientFormData.put(agencyEndPoints.updateProfile(agencyId), agencyData);
  return results?.data;
};

export const getAgencyTypes = async () => {
  const results = await axiosClient.get(agencyEndPoints.getAgencyTypes);
  return results?.data;
};

export const createAgencyUser = async (newUser) => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.post(agencyEndPoints.createAgencyUser(agencyId), newUser);
  return results?.data;
};

export const getAgencyUsers = async () => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.get(agencyEndPoints.getAgencyUsers(agencyId));
  return results?.data;
};
export const getAgencyRoles = async () => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.get(agencyEndPoints.getAgencyRoles(agencyId));
  return results?.data;
};
export const createAgencyRole = async (roleData) => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.post(agencyEndPoints.createAgencyRole(agencyId), roleData);
  return results?.data;
};
export const updateAgencyRole = async (roleData) => {
  const results = await axiosClient.put(agencyEndPoints.updateAgencyRole, roleData);
  return results?.data;
};

export const enrollToMarketPlaceSaas = async () => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosTravenaApiClient.post(agencyEndPoints.enrollMarketPlaceSaas(agencyId));
  return results?.data;
};

export const enrollMarketPlaceOnPrem = async (agencyDetails) => {
  const results = await axiosTravenaApiClient.post(agencyEndPoints.enrollMarketPlaceOnPrem, agencyDetails);
  return results?.data;
};

export const getAgencyDashboard = async () => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.get(agencyEndPoints.getDashboard(agencyId));
  return results?.data;
};

export const createAgencyPersonel = async (newPersonel) => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.post(agencyEndPoints.agencyOperators(agencyId), newPersonel);
  return results?.data;
};

export const createAgencyPersonelType = async (newType) => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.post(agencyEndPoints.operatorTypes(agencyId), newType);
  return results?.data;
};

export const getAgencyPersonel = async () => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.get(agencyEndPoints.agencyOperators(agencyId));
  return results?.data;
};

export const getPersonelType = async () => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.get(agencyEndPoints.operatorTypes(agencyId));
  return results?.data;
};

// Equipments
export const createAgencyEquipment = async (newEquipment) => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.post(agencyEndPoints.agencyEquipments(agencyId), newEquipment);
  return results?.data;
};

export const createAgencyEquipmentType = async (newType) => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.post(agencyEndPoints.equipmentTypes(agencyId), newType);
  return results?.data;
};

export const getAgencyEquipment = async () => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.get(agencyEndPoints.agencyEquipments(agencyId));
  return results?.data;
};

export const getEquipmentTypes = async () => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.get(agencyEndPoints.equipmentTypes(agencyId));
  return results?.data;
};

export const saveSettlementAcct = async (accountDetails) => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.post(agencyEndPoints.settlementAcct(agencyId), accountDetails);
  return results?.data;
};

export const getAgencySettlementAcct = async () => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.get(agencyEndPoints.settlementAcct(agencyId));
  return results?.data;
};

export const getAgencySettlements = async () => {
  const { agencyId } = getCookie('UserInfo');
  const results = await axiosClient.get(agencyEndPoints.settlements(agencyId));
  return results?.data;
};

export const getBankNames = async () => {
  const results = await axiosClient.get(agencyEndPoints.bankNames);
  return results?.data;
};

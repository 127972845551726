/* eslint-disable react/prop-types */
import { Button } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import RoleForm from '../roleForm/RoleForm';
import { Toast } from '../../../../axios/Toast';
import { updateAgencyRole } from '../../../../api/agency.api';

const RoleDetails = ({ setOpenState, refetchRoles, roleDetails }) => {
  const [state, setState] = useState(roleDetails);

  const putRoleMutation = useMutation(['updateRole'], () => updateAgencyRole(), {
    onMutate: () => {
      Toast.loading('Loading, please wait...');
    },
    onError: () => {
      Toast.error('An error occurred');
    },
    onSuccess: () => {
      Toast.success('Role created successfully');
      refetchRoles();
      setOpenState(false);
    },
  });

  const onChange = ({ name, value }) => {
    setState({ ...state, [name]: value });
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">Role Details</div>
        <div className="slider-header-buttons">
          <Button
            variant="outlined"
            sx={{
              marginRight: 5,
            }}
            onClick={() => setOpenState(false)}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={() => putRoleMutation.mutate()}>
            Save
          </Button>
        </div>
      </div>
      <div className="slider-display-body">
        <RoleForm state={state} dispatch={onChange} />
      </div>
    </div>
  );
};

export default RoleDetails;
